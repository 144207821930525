<template>
  <el-dialog title="订阅流水" :visible.sync="dialogVisible" width="70%" append-to-body>
    <el-table
      :data="listData"
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="id" label="编号" width="80px"/>
      <el-table-column prop="signalType" label="信号类型"/>
      <el-table-column label="订阅费">
        <template slot-scope="scope">
          <span>{{getIsFree(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付渠道">
        <template slot-scope="scope">
          <span>{{getChannel(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payCurrency" label="支付币种">
        <template slot-scope="scope">
          <span>{{scope.row.payCurrency || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" label="支付金额">
        <template slot-scope="scope">
          <span>{{scope.row.payAmount || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="350px">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :hide-on-single-page="true"
      :total="total"
      :current-page.sync="page"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="dialogSizeChange"
      @current-change="dialogPageChange"
    />
  </el-dialog>
</template>

<script>

  import { parseTime } from "@/utils/index";
  export default {
    name: 'subscribeList',
    props: {
      listData: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        page: 1,
        size: 10,
        total: 0,
        dialogVisible: false
      }
    },
    methods: {
      parseTime,
      dialogSizeChange(){
        this.$parent.fetchListData();
      },
      dialogPageChange(){
        this.$parent.fetchListData();
      },
      getIsFree(row){
        return `${row.subscribePrice}`;
      },
      getChannel(row){
        switch (row.payChannel) {
          case 1:
            return '支付宝';
          case 2:
            return '微信';
          case 3:
            return '代币';
          default:
            return '-';
        }
      },
      getStatus(status){
        switch (status) {
          case 0:
            return '有效';
          case 1:
            return '已过期';
          case 2:
            return '取消订阅';
          case 3:
            return '失败';
          case 4:
            return '待上传凭证';
          case 5:
            return '待确认';
          case 6:
            return '已取消';
          case 7:
            return '已投诉';
          case 8:
            return '已拒绝';
        }
      }
    }
  }
</script>

<style scoped>

</style>
