<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-input
      v-model="query.login"
      clearable
      placeholder="输入订阅者MT4"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
      @input="copyLoginInputHandle"
    />
    <el-input v-model="query.userName" clearable placeholder="输入订阅者昵称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input
      v-model="query.signalLogin"
      clearable
      placeholder="输入信号源MT4"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
      @input="signalLoginInputHandle"
    />
    <el-input v-model="query.signalUserName" clearable placeholder="输入信号源昵称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.status" clearable placeholder="订阅状态" class="filter-item" style="width: 150px" @change="toQuery">
      <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"/>
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: {
    eForm,
    searchCommunity
  },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 'login', display_name: '订阅者MT4' },
        { key: 'userName', display_name: '订阅者昵称' },
        { key: 'signalLogin', display_name: '信号源MT4' },
        { key: 'signalUserName', display_name: '信号源昵称' },
        { key: 'siteName', display_name: '站点名称' }
      ],
      statusList:[
        { id: 0, label: "有效" },
        { id: 1, label: "失效" },
        { id: 2, label: "取消订阅" },
        { id: 3, label: "通讯失败" }
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    },
    signalLoginInputHandle(){
      this.$emit('signalLoginInput');
    },
    copyLoginInputHandle(){
      this.$emit('copyLoginInput');
    },
  }
}
</script>
