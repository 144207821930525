<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="isAdd ? '新增' : '编辑'"
    width="800px"
  >
    <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="ID" hidden>
        <el-input v-model="form.id" style="width: 100px;" />
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="跟单类型">
            <el-radio
              v-model="form.copyType"
              :label="1"
            >固定数量</el-radio>
            <el-radio
              v-model="form.copyType"
              :label="2"
            >比例</el-radio>
            <el-radio
              v-model="form.copyType"
              :label="3"
            >智能比例</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="form.copyType == 3" :label="getLabel()">
            <el-input v-model="samrkCopyValue" style="width: 220px;" />
          </el-form-item>
          <el-form-item v-if="form.copyType == 2" :label="getLabel()">
            <el-input v-model="rateCopyValue" style="width: 220px;" />
          </el-form-item>
          <el-form-item v-if="form.copyType == 1" :label="getLabel()">
            <el-input v-model="numberCopyValue" style="width: 220px;" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="最大持仓手数">
            <el-input v-model="form.copyMaxLots" style="width: 220px;" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="最大持仓笔数">
            <el-input v-model="form.copyMaxNum" style="width: 220px;" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.beginDate" type="datetime" placeholder="选择日期时间" disabled></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="到期时间">
            <el-date-picker v-model="form.expireDate" type="datetime" placeholder="选择日期时间" disabled></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="跟单方向">
            <el-radio v-model="form.copyDirection" :label="1">正向</el-radio>
            <el-radio v-model="form.copyDirection" :label="2">方向</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="跟单状态">
            <el-radio v-model="form.copyStatus" label="1">正常</el-radio>
            <el-radio v-model="form.copyStatus" label="0">停止</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订阅状态">
            <!-- <el-input v-model="form.status" style="width: 220px;" /> -->
            <el-select v-model="form.status" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  add,
  edit
} from "@/api/community/srCommunitySubscription";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        id: "",
        copyType: "",
        copyMaxLots: "",
        copyMaxNum: "",
        copyStatus: "",
        beginDate: "",
        expireDate: "",
        status: "",
        copyDirection: "",
      },
      options: [
        {
          value: '0',
          label: '有效'
        },
        {
          value: '1',
          label: '失效'
        },
        {
          value: '2',
          label: '取消订阅'
        },
        {
          value: '3',
          label: '通讯失败'
        }
      ],
      samrkCopyValue: "",
      rateCopyValue: "",
      numberCopyValue: "",
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    doAdd() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.copyValue = this.getCopyValue();
      if(params.copyType == 3 && this.getCopyValue() === ''){
        params.copyValue = 999999999.99;
      }

      add(params)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.copyValue = this.getCopyValue();
      if(params.copyType == 3 && this.getCopyValue() === ''){
        params.copyValue = 999999999.99;
      }
      edit(params)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.sup_this.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    getCopyValue(){
      switch (this.form.copyType) {
        case 3:
          return this.samrkCopyValue;
          break;
        case 2:
          return this.rateCopyValue;
          break;
        case 1:
          return this.numberCopyValue;
          break;
      }
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        copyType: "",
        copyValue: "",
        copyMaxLots: "",
        copyMaxNum: "",
        copyStatus: "",
        beginDate: "",
        expireDate: "",
        status: ""
      };
    },
    getLabel(){
      if(this.form.copyType == 3){
        return '最大值';
      }else {
        return '跟单值'
      }
    },
  }
};
</script>

<style scoped>
</style>
