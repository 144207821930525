<template>
  <div class="app-container">
    <eHeader
      ref="header"
      :query="query"
      @signalLoginInput="signalLoginInput"
      @copyLoginInput="copyLoginInput"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="ID" width="70px" />
      <el-table-column prop="siteName" label="社区名称" />
      <el-table-column prop="login" label="订阅者MT4" />
      <el-table-column prop="userName" label="订阅者昵称" />
      <el-table-column prop="signalLogin" label="信号源MT4" />
      <el-table-column prop="signalUserName" label="信号源昵称" />
      <el-table-column prop="copyType" label="跟随策略">
        <template slot-scope="scope">
          <span>{{getType(scope.row.copyType)}}</span>
          <span>{{scope.row.copyValue}}{{scope.row.copyType==1 ? "手": "倍"}}
             ({{scope.row.copyDirection == 1 ? "正": "反"}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="copyMaxLots" label="最大持仓手数/笔数" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.copyMaxLots || "-"}}/{{scope.row.copyMaxNum || "-"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="跟单状态">
        <template slot-scope="scope">
          <span>{{scope.row.copyStatus==0?'暂停':'正常'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="term" label="有效期" width="250px">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.beginDate)+"~"+parseTime(scope.row.expireDate)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="订阅状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">有效</span>
          <span v-if="scope.row.status==1">失效</span>
          <span v-if="scope.row.status==2">取消订阅</span>
          <span v-if="scope.row.status==3">通讯失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="subscriptionTime" label="订阅时间" width="150px">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.subscriptionTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_EDIT'])" :data="scope.row" :sup_this="sup_this" />
          <el-button v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_VIEW'])" type="primary" size="mini" @click="openDetail(scope.row)">订阅流水</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"
    />

    <subscribe-list
      ref="list"
      :list-data="listData"
    ></subscribe-list>
  </div>
</template>

<script>
  import { limitMt4Number } from '@/utils/validate'
  import { getData } from '@/api/community/srCommunitySubscription'
  import subscribeList from "@/components/community/subscription/sub/subscribeList"
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { parseTime } from "@/utils/index";
import eHeader from "@/components/community/subscription/sub/header";
import edit from "@/components/community/subscription/sub/edit";
export default {
  name:'sub',
  components: { eHeader, edit,subscribeList },
  mixins: [initData],
  data() {
    return {
      sup_this: this,
      dialogVisible: false,
      subscriptionTransData: [],
      listParams:{
        login: '',
        signalLogin: '',
      },
      listData:[],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    signalLoginInput(){
      this.query.signalLogin = limitMt4Number(this.query.signalLogin);
    },
    copyLoginInput(){
      this.query.login = limitMt4Number(this.query.login);
    },
    getType(copyType){
      switch (copyType) {
        case 3:
          return '智能比例';
        case 1:
          return '固定数量';
        case 2:
          return '比例';
      }
    },
    beforeInit() {
      this.url = "community/crm/srCommunitySubscription";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      const query = this.query;
      const siteName = query.siteName;
      const login = query.login;
      const userName = query.userName;
      const signalLogin = query.signalLogin;
      const signalUserName = query.signalUserName;
      const status = query.status;
      if (this.$refs.header.$refs.searchcommunity.siteName) {
          this.params['siteName'] = this.$refs.header.$refs.searchcommunity.siteName
      }
      if (login !== "" && login !== null) {
        this.params["login"] = login;
      }
      if (userName !== "" && userName !== null) {
        this.params["userName"] = userName;
      }
      if (signalLogin !== "" && signalLogin !== null) {
        this.params["signalLogin"] = signalLogin;
      }
      if (signalUserName !== "" && signalUserName !== null) {
        this.params["signalUserName"] = signalUserName;
      }
      if (status !== "" && status !== null) {
        this.params["status"] = status;
      }
      return true;
    },
    openDetail(row) {
      this.listParams = {
        login: row.login,
        signalLogin: row.signalLogin,
      }
      getData({
        copyInfo: this.listParams.login,
        signalInfo: this.listParams.signalLogin,
        sort: 'id,desc',
        page: this.$refs.list.page -1,
        size: this.$refs.list.size
      }).then(res => {
        this.listData = res.content
        this.$refs.list.total = res.totalElements;
        this.$refs.list.dialogVisible = true;
      }).catch(err => {
      });
    },
    fetchListData(){
      getData({
        copyInfo: this.listParams.login,
        signalInfo: this.listParams.signalLogin,
        sort: 'id,desc',
        page: this.$refs.list.page -1,
        size: this.$refs.list.size
      }).then(res => {
        this.listData = res.content
        this.$refs.list.total = res.totalElements;
      }).catch(err => {
      })
    },
  }
};
</script>

<style scoped>
</style>
